import React from "react"
import Img from "gatsby-image"
import styled from "styled-components"

const Container = styled.div`
  width: 100%;
  position: relative;
  box-shadow: 0 -2px 84px -14px rgba(93, 96, 113, 0.42);
  margin-top: 55px;
`
const Wrapper = styled.div`
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 1050px;
  margin: 0 auto;
  text-align: center;
  height: 200px;
`
const CompanyName = styled.span`
  font-size: 71px;
  font-weight: 700;
  color: white;
  display: block;

  @media (max-width: 630px) {
    font-size: 42px;
  }
`
const HelpText = styled.span`
  font-size: 32px;
  font-weight: 600;
  color: white;

  @media (max-width: 630px) {
    font-size: 24px;
  }
`
const FiltrImg = styled(Img)`
  filter: brightness(0.4);

  @media (max-width: 630px) {
    height: 300px;
  }
`
const LogoWrp = styled.div`
  height: 200px;
`

export default ({ banner, logo }) => (
  <Container>
    <Wrapper>
      <CompanyName>RM MEDICAL</CompanyName>
      <HelpText>Eksperci dla Ekspertów</HelpText>
      {/* <LogoWrp>
        <Img fluid={logo.childImageSharp.fluid} />
      </LogoWrp> */}
    </Wrapper>
    <FiltrImg fluid={banner.childImageSharp.fluid} />
  </Container>
)
