import React from "react"
import styled from "styled-components"

const Container = styled.div`
  width: 100%;
`
const Content = styled.div`
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
`
const Row = styled.div`
  width: 100%;
  display: flex;
  margin-top: ${({ top }) => top && top};

  @media (max-width: 630px) {
    flex-direction: column;
    width: 92%;
    margin: 0 auto;
  }
`

const ImageWrapper = styled.div`
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 630px) {
    display: none;
  }
`
const TextWrapper = styled.div`
  width: 55%;
  @media (max-width: 630px) {
    width: 100%;
  }
  //   margin-bottom: 65px;
`
const HeaderWrapper = styled.div`
  margin: 60px 0 30px 0;
  h2 {
    margin: 0;
    padding: 0;
    font-size: 32px;
    font-weight: 600;
  }
`
const ImageTwoWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55%;

  @media (max-width: 630px) {
    display: none;
  }
`
const ImageBlock = styled.div`
  width: 94%;
  height: 260px;
  background-position: center;
  background-size: cover;
  max-width: 440px;
  box-shadow: 0 5px 45px -10px rgba(143, 146, 154, 0.87);
  border-radius: 10px;
`
const ImageBlockTwo = styled.div`
  width: 300px;
  height: 307px;
  background-position: center;
  background-size: cover;
  max-width: 440px;
`
const LineHeader = styled.div`
  width: 50px;
  height: 4px;
  background: #2b5cff;
  border-radius: 20px;
  margin-top: 10px;
`
const NumericList = styled.ol`
  counter-reset: item;
  padding-left: 5px;

  li {
    margin: 5px 0;
    padding: 0 0 0 2em;
    text-indent: -2em;
    list-style-type: none;
    counter-increment: item;
  }

  li:before {
    display: inline-block;
    width: 1.5em;
    padding-right: 0.5em;
    font-weight: bold;
    text-align: right;
    content: counter(item) ".";
  }
`
const ImageMobile = styled.div`
  display: none;
  width: 100%;
  height: 198px;
  background-size: cover;
  border-radius: 5px;
  margin-bottom: 35px;

  @media (max-width: 630px) {
    display: block;
  }
`

export default ({ imgOne, imgTwo, toRefS, toRefP }) => (
  <Container>
    <Content>
      <Row>
        <ImageWrapper>
          <ImageBlock
            style={{
              backgroundImage: "url(" + imgOne.childImageSharp.fluid.src + ")",
            }}
          ></ImageBlock>
        </ImageWrapper>
        <TextWrapper>
          <HeaderWrapper ref={toRefS}>
            <h2>Serwis</h2>
            <LineHeader />
          </HeaderWrapper>
          <div>
            <ImageMobile
              style={{
                backgroundImage:
                  "url(" + imgOne.childImageSharp.fluid.src + ")",
              }}
            ></ImageMobile>
          </div>
          <div>
            <p>
              Kompleksowa obsługa oddziałów endoskopii:
              <ul>
                <li>naprawy</li>
                <li>konserwacja</li>
                <li>doradztwo</li>
                <li>szkolenia</li>
                <li>przeglądy techniczne urządzeń medycznych</li>
              </ul>
            </p>
            <p>
              Nasza wykwalifikowana kadra nieustannie pogłębia swoją wiedzę w
              tracie szkoleń, dzięki czemu zapewniamy Państwu najwyższą jakość
              usług. Naprawy realizowane przez naszą firmę są zgodne z prawem o
              produkcie medycznym i z prawem o użytkowanie produktu medycznego.
            </p>
            <p>
              <b>Nasza oferta obejmuje:</b>
              <ul>
                <li>
                  naprawę endoskopów giętkich wszystkich producentów( Olympus,
                  Pentax, Fujinon, Storz)
                </li>
                <li>naprawę endoskopów endosonograficznych</li>
                <li>przeglądy okresowe endoskopów giętkich</li>
                <li>
                  przeglądy okresowe sprzętu peryferyjnego wszystkich
                  producentów (źródła światła, procesory wizyjne, monitory itp.)
                </li>
                <li>
                  szkolenie personelu z zakresu obsługi sprzętu endoskopowego
                </li>
              </ul>
            </p>
          </div>
        </TextWrapper>
      </Row>
    </Content>
    <Content>
      <Row top="20px">
        <TextWrapper>
          <HeaderWrapper ref={toRefP}>
            <h2>Proces</h2>
            <LineHeader />
          </HeaderWrapper>
          <NumericList>
            <li>Zgłoszenie uszkodzenia aparatu</li>
            <li>Bezpłatny odbiór aparatu z placówki</li>
            <li>
              Bezpłatny aparat zastępczy na czas naprawy dostarczony do placówki
              w ciągu max 72h
            </li>
            <li>
              Szczegółowa kontrola w 50 punktach kontrolnych aparatu oraz opis
              uszkodzeń wraz z dokumentacją zdjęciową.
            </li>
            <li>Kosztorys naprawy aparatu</li>
            <li>Naprawa aparatu zgodna z ISO 13485 po akceptacji kosztorysu</li>
            <li>
              Po zakończonej naprawie wysyłka aparatu wraz z dokumentacja
              dopuszczającą do użycia oraz 6 miesięczną gwarancją
            </li>
          </NumericList>
        </TextWrapper>
        <ImageTwoWrapper>
          <ImageBlockTwo
            style={{
              backgroundImage: "url(" + imgTwo.childImageSharp.fluid.src + ")",
            }}
          ></ImageBlockTwo>
        </ImageTwoWrapper>
      </Row>
    </Content>
  </Container>
)
