const mapboxgl = require("mapbox-gl/dist/mapbox-gl.js")
require("mapbox-gl/dist/mapbox-gl.css")

mapboxgl.accessToken =
  "pk.eyJ1Ijoia21rYXppbWllcmN6YWsiLCJhIjoiY2p6eHlibmUwMG42ZDNkbzRncXlyZ3F4bCJ9.faWjMIrJILs5HrFnem-KBg"

class Map {
  constructor(id) {
    this.id = id
    this.mapObj = null
  }
  inti() {
    this.mapObj = new mapboxgl.Map({
      container: this.id,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [19.938212, 50.057835],
      zoom: 18,
    })
  }

  initMarker() {
    new mapboxgl.Marker().setLngLat([19.938212, 50.057835]).addTo(this.mapObj)
  }
}

export default Map
