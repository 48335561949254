import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"

const Container = styled.div`
  max-width: 100%;
  padding: 20px 10px;
  background: white;
  box-shadow: 0 1px 23px rgba(17, 18, 27, 0.22);
  z-index: 999;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;

  @media (max-width: 630px) {
    padding: 10px 10px;
  }
`
const Wrapper = styled.div`
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 630px) {
    align-items: center;
  }
`
const Logo = styled.div`
  width: 50px;
  margin-right: 10px;
  margin-left: 10px;
  font-size: 18px;
  font-weight: 700;
`
const Row = styled.div`
  display: flex;

  @media (max-width: 630px) {
    display: none;
  }
`
const Item = styled.a`
  margin: 0 10px;
  cursor: pointer;
  color: #768190;
  transition: all 250ms ease-in;
  display: block;
  width: 90px;

  :hover {
    font-weight: 600;
    color: #161c25;
  }

  @media (max-width: 630px) {
    margin: 20px 0;
    text-align: center;
    color: #efeff2;
    font-size: 28px;
    font-weight: 600;
    width: auto;
    :hover {
      color: #efeff2;
    }
  }
`
const MobileNav = styled.div`
  display: none;
  @media (max-width: 630px) {
    display: block;
    height: 100%;
    width: 24px;
    padding: 10px 20px;
  }
`
const MobileNavContent = styled.div`
  position: fixed;
  right: 0;
  width: 100%;
  max-width: 420px;
  bottom: 0;
  top: 0;
  background: #191e29;
  z-index: 999999999;
  box-shadow: -2px 0 80px -10px rgba(25, 30, 41, 0.5);
  transition: all 0.3s ease;
  display: none;

  @media (min-width: 631px) {
    display: none !important;
  }
`
const MobileWrapper = styled.div`
  width: 92%;
  margin: 0 auto;
`
const MobileIcon = styled.div`
  width: 80%;
  max-width: 52px;
  margin: 50px auto 20px auto;
  color: white;
`

export default class Navigation extends React.Component {
  render() {
    return (
      <Container>
        <Wrapper>
          <Logo>
            <Img fluid={this.props.logo.childImageSharp.fluid} />
          </Logo>
          <Row>
            <Item onClick={this.props.about}>O nas</Item>
            <Item onClick={this.props.services}>Serwis</Item>
            <Item onClick={this.props.proces}>Proces</Item>
            <Item onClick={this.props.contact}>Kontakt</Item>
          </Row>
          <MobileNav onClick={this.props.openMobile}>
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="bars"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
              class="svg-inline--fa fa-bars fa-w-14 fa-2x"
            >
              <path
                fill="currentColor"
                d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
                class=""
              ></path>
            </svg>
          </MobileNav>
        </Wrapper>
        <MobileNavContent ref={this.props.nav}>
          <MobileWrapper>
            <MobileIcon onClick={this.props.closeNav}>
              <svg
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 448c-110.5 0-200-89.5-200-200S145.5 56 256 56s200 89.5 200 200-89.5 200-200 200zm101.8-262.2L295.6 256l62.2 62.2c4.7 4.7 4.7 12.3 0 17l-22.6 22.6c-4.7 4.7-12.3 4.7-17 0L256 295.6l-62.2 62.2c-4.7 4.7-12.3 4.7-17 0l-22.6-22.6c-4.7-4.7-4.7-12.3 0-17l62.2-62.2-62.2-62.2c-4.7-4.7-4.7-12.3 0-17l22.6-22.6c4.7-4.7 12.3-4.7 17 0l62.2 62.2 62.2-62.2c4.7-4.7 12.3-4.7 17 0l22.6 22.6c4.7 4.7 4.7 12.3 0 17z"
                  class=""
                ></path>
              </svg>
            </MobileIcon>
            <div>
              <Item onClick={this.props.about}>O nas</Item>
              <Item onClick={this.props.services}>Serwis</Item>
              <Item onClick={this.props.proces}>Proces</Item>
              <Item onClick={this.props.contact}>Kontakt</Item>
            </div>
          </MobileWrapper>
        </MobileNavContent>
      </Container>
    )
  }
}
