import React from "react"
import styled from "styled-components"

const Container = styled.div`
  width: 100%;
  background: #f2f2f5;
`
const Content = styled.div`
  width: 100%;
  max-width: 1250px;
  margin: 0 auto;
`
const Row = styled.div`
  width: 100%;
  display: flex;

  @media (max-width: 630px) {
    flex-direction: column;
    width: 92%;
    margin: 0 auto;
  }
`
const TextWrapper = styled.div`
  width: 55%;
  margin-bottom: 65px;

  @media (max-width: 630px) {
    width: 100%;
  }
`
const HeaderWrapper = styled.div`
  margin: 60px 0 30px 0;
  h2 {
    margin: 0;
    padding: 0;
    font-size: 32px;
    font-weight: 600;
  }
`
const LineHeader = styled.div`
  width: 50px;
  height: 4px;
  background: #2b5cff;
  border-radius: 20px;
  margin-top: 10px;
`
const InfoWrapper = styled.div`
  padding: 0 10px;
`
const InfoText = styled.div``

const ImageWrapper = styled.div`
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 630px) {
    display: none;
  }
`
const ImageBlock = styled.div`
  width: 269px;
  height: 355px;
  background-position: center;
  background-size: cover;
  box-shadow: 0 5px 45px -10px rgba(143, 146, 154, 0.87);
  border-radius: 10px;
`
const Btn = styled.button`
  font-size: 14px;
  padding: 18px 59px;
  border-radius: 34px;
  border: none;
  background: #2b5bff;
  color: white;
  font-weight: 600;
  cursor: pointer;
  margin-top: 45px;
  outline: none;
  box-shadow: 0 2px 25px -4px rgba(43, 90, 255, 0.42);
`

export default ({ aboutImage, toRef, toContact }) => (
  <Container>
    <Content>
      <Row>
        <TextWrapper>
          <HeaderWrapper ref={toRef}>
            <h2>O nas</h2>
            <LineHeader />
          </HeaderWrapper>
          <InfoWrapper>
            <InfoText>
              <p>
                Wraz z naszymi partnerami od ponad 10 lat zapewniamy kompleksową
                obsługę działów endoskopii w placówkach służby zdrowia.
                Wychodząc naprzeciw coraz wyższym wymaganiom naszych klientów
                mamy przyjemność przedstawić ofertę obsługi serwisowej oraz
                utrzymania sprzętu endoskopowego w Państwa placówce.
              </p>
            </InfoText>
            <InfoText>
              <p>
                Ponad 10 letnie doświadczenie w naprawie endoskopów giętkich
                zgodnie z wymogami ISO 13485 czyni nas alternatywą dla
                autoryzowanych serwisów pod względem obsługi oraz kosztów
                naprawy sprzętu endoskopowego niższych nawet do 60 %.
              </p>
            </InfoText>
            <InfoText>
              <p>
                Nasze procesy napraw są w całości przeprowadzane zgodnie z
                posiadanymi przez nas certyfikatami oraz wymaganiami prawa o
                wyrobie medycznym, daję nam to podstawę prawną do
                przeprowadzania napraw niezależnie od producenta.
              </p>
            </InfoText>
            <div>
              <Btn onClick={toContact}>Kontakt</Btn>
            </div>
          </InfoWrapper>
        </TextWrapper>
        <ImageWrapper>
          <ImageBlock
            style={{
              backgroundImage:
                "url(" + aboutImage.childImageSharp.fluid.src + ")",
            }}
          ></ImageBlock>
        </ImageWrapper>
      </Row>
    </Content>
  </Container>
)
