import React from "react"
import styled, { keyframes, css } from "styled-components"

const shake = keyframes`
  0% {
    transform: translateX(0px);
  }

  25% {
    transform: translateX(8px);
  }

  50% {
    transform: translateX(0px);
  }

  75% {
    transform: translateX(-8px);
  }
  
  100% {
    transform: translateX(0px);
  }
`

const Container = styled.div`
  width: 100%;
  background: #1a1e29;
  margin-top: 65px;
`
const Row = styled.div`
  display: flex;
  @media (max-width: 630px) {
    flex-direction: column-reverse;
  }
`
const MapCol = styled.div`
  width: 55%;
  max-width: 900px;
  min-height: 500px;

  @media (max-width: 630px) {
    width: 100%;
    margin-top: 25px;
  }
`
const ContactCol = styled.div`
  width: 40%;
  margin: 0 0 0 4%;

  @media (max-width: 630px) {
    width: 92%;
    margin: 0 auto;
  }
`
const HeaderWrapper = styled.div`
  margin: 40px 0 30px 0;
  h2 {
    margin: 0;
    padding: 0;
    font-size: 32px;
    font-weight: 600;
    color: white;
    animation: ${({ an }) =>
      an &&
      css`
        ${shake} 250ms linear 6
      `};
  }
`
const LineHeader = styled.div`
  width: 50px;
  height: 4px;
  background: #2b5cff;
  border-radius: 20px;
  margin-top: 10px;
`
const ContactInfo = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
`
const WhiteLine = styled.div`
  width: 145px;
  height: 1px;
  background: rgba(255, 255, 255, 0.38);
  margin: 10px 0;
`
const HighlightSpan = styled.span`
  font-size: 18px;
  font-weight: 600;
  margin-left: 5px;
`
const HeaderBrand = styled.span`
  font-size: 16px;
  font-weight: 600;
`

export default ({ toRef, activeAnimation }) => (
  <Container>
    <Row>
      <MapCol id="map"></MapCol>
      <ContactCol>
        <HeaderWrapper ref={toRef} an={activeAnimation}>
          <h2>Kontakt</h2>
          <LineHeader />
        </HeaderWrapper>
        <ContactInfo>
          <HeaderBrand>RM MEDICAL Sp. Z O.O.</HeaderBrand>
          <WhiteLine />
          <span>
            telefon <HighlightSpan>+48 665 148 035</HighlightSpan>
          </span>
          <span>
            e-mail <HighlightSpan>serwis@rm-medical.pl</HighlightSpan>
          </span>
          <span>
            ulica <HighlightSpan>Grodzka 42 lok. 1</HighlightSpan>
          </span>
          <span>
            kod pocztowy <HighlightSpan>31-044 Kraków</HighlightSpan>
          </span>
        </ContactInfo>
      </ContactCol>
    </Row>
  </Container>
)
