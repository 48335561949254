import React from "react"
import styled, { createGlobalStyle } from "styled-components"

import Navigation from "../components/Navigation"
import Home from "../components/Home"
import About from "../components/About"
import Services from "../components/Services"
import Contact from "../components/Contact"

import Map from "../components/Map"

const GlobalStyle = createGlobalStyle`
  html, body {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
    font-size: 16px;
  }
`

class Index extends React.Component {
  constructor(props) {
    super(props)
    this.about = React.createRef()
    this.services = React.createRef()
    this.proces = React.createRef()
    this.contact = React.createRef()
    this.nav = React.createRef()
    this.state = {
      contact: false,
    }
  }

  openMobile = () => {
    this.nav.current.style.display = "block"
    this.nav.current.style.transform = "translateX(450px)"
    setTimeout(() => {
      this.nav.current.style.transform = "translateX(0px)"
    }, 10)
  }

  closeMobile = () => {
    this.nav.current.style.transform = "translateX(450px)"
    setTimeout(() => {
      this.nav.current.style.display = "none"
    }, 350)
  }

  scrollToAbout = () => {
    window.scrollTo({
      top: this.about.current.offsetTop,
      behavior: "smooth",
    })

    this.nav.current.style.transform = "translateX(450px)"
    setTimeout(() => {
      this.nav.current.style.display = "none"
    }, 340)
  }

  scrollToServices = () => {
    window.scrollTo({
      top: this.services.current.offsetTop,
      behavior: "smooth",
    })

    this.nav.current.style.transform = "translateX(450px)"
    setTimeout(() => {
      this.nav.current.style.display = "none"
    }, 340)
  }

  scrollToProces = () => {
    window.scrollTo({
      top: this.proces.current.offsetTop,
      behavior: "smooth",
    })

    this.nav.current.style.transform = "translateX(450px)"
    setTimeout(() => {
      this.nav.current.style.display = "none"
    }, 340)
  }

  scrollToContact = () => {
    this.setState({ contact: false })

    window.scrollTo({
      top: this.contact.current.offsetTop,
      behavior: "smooth",
    })
    setTimeout(() => {
      this.setState({ contact: true })
    }, 300)

    this.nav.current.style.transform = "translateX(450px)"
    setTimeout(() => {
      this.nav.current.style.display = "none"
    }, 340)
  }

  componentDidMount() {
    const m = new Map("map")
    m.inti()
    setTimeout(() => {
      m.initMarker()
    }, 300)
  }

  render() {
    return (
      <React.Fragment>
        <GlobalStyle />
        <div>
          <Navigation
            about={this.scrollToAbout}
            services={this.scrollToServices}
            proces={this.scrollToProces}
            contact={this.scrollToContact}
            nav={this.nav}
            openMobile={this.openMobile}
            closeNav={this.closeMobile}
            logo={this.props.data.logo}
          >
            {/* <span>xD</span> */}
          </Navigation>
          <Home banner={this.props.data.banner} logo={this.props.data.logo} />
          {console.log(this.props.data)}
          <About
            aboutImage={this.props.data.about}
            toRef={this.about}
            toContact={this.scrollToContact}
          />
          <Services
            imgOne={this.props.data.s1}
            imgTwo={this.props.data.s2}
            toRefS={this.services}
            toRefP={this.proces}
          />
          <Contact toRef={this.contact} activeAnimation={this.state.contact} />
        </div>
      </React.Fragment>
    )
  }
}

export default Index

export const query = graphql`
  query {
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, maxHeight: 200) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    banner: file(relativePath: { eq: "zd1.png" }) {
      childImageSharp {
        fluid(maxWidth: 2300, maxHeight: 800) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    about: file(relativePath: { eq: "about.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 620, maxHeight: 920) {
          src
        }
      }
    }
    s1: file(relativePath: { eq: "s1.png" }) {
      childImageSharp {
        fluid(maxWidth: 442, maxHeight: 290) {
          src
        }
      }
    }
    s2: file(relativePath: { eq: "s2.png" }) {
      childImageSharp {
        fluid(maxWidth: 447, maxHeight: 504) {
          src
        }
      }
    }
  }
`
